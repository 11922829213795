import CircularProgress from "components/common/CircularProgress/CircularProgress";
import React, { FC, ReactNode, useMemo } from "react";
import UploadDeleteIcon from "../../assets/UploadDeleteIcon";
import UploadImageView from "../UploadImageView/UploadImageView";
import { UploadImageCardStyled } from "./UploadImageCard.style";
import { IUploadImageCard } from "./UploadImageCard.types";

const UploadImageCard: FC<IUploadImageCard> = ({
  imgUrl,
  setUploadImageUrlList,
  index,
  name,
  setValue,
}) => {
  const deleteImageUrlListElemnt = (): void => {
    setUploadImageUrlList((prev: string[]) => {
      let filterUploadImageUrlList = prev.filter(
        (_: string, i: number) => i !== index
      ) as string[];
      setValue(name, filterUploadImageUrlList);
      return filterUploadImageUrlList;
    });
  };

  const renderCardContent = useMemo((): ReactNode => {
    if (imgUrl) {
      return (
        <div className="upload-result-image-content">
          <div className="upload-result-image-actions d-flex align-items-center justify-content-center">
            <div>
              <UploadImageView
                imgUrl={process.env.REACT_APP_BASE_URL + imgUrl}
              />
              <span onClick={deleteImageUrlListElemnt}>
                <UploadDeleteIcon />
              </span>
            </div>
          </div>
          <img
            src={process.env.REACT_APP_BASE_URL + imgUrl}
            className="upload-result-image"
          />
        </div>
      );
    } else
      return (
        <CircularProgress thickness={2.8} size={13} animationDuration={900} />
      );
  }, [imgUrl]);

  return <UploadImageCardStyled>{renderCardContent}</UploadImageCardStyled>;
};

export default UploadImageCard;
